import { useState } from "react";
import del from "../../Assets/SideBar-Image/del.png";
import { ConfirmDelete } from "../../Utills/ConfirmAlerts";
import NoData from "../../Utills/NoData/NoData";
import RichTag from "../../Utills/RichTag/RichTage";

function AddPackageToEvent(props: any) {
  const { values, setValues, packageList, venueName } = props;

  // handle click event of the Remove button
  const handleRemoveClick = (index: any) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
  };

  // handle click event of the Add button
  const handleAddClick = (item: any) => {
    const list = [...values];
    const obj = {
      title: "",
      description: "",
      ticketLink: "",
      price: "",
      packageType: "",
      _id: null,
    };

    if (item != null) {
      obj.title = item.title;
      obj.description = item.description;
      obj.packageType = item.packageType;
      obj._id = item._id;
    }

    list.unshift(obj);
    setValues(list);
  };
  return (
    <div>
      <div className="pack-buttons">
        <div className="dropdowns">
          <button
            className=" addpack"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            ADD PACKAGES
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li
              onClick={() => {
                handleAddClick(null);
              }}
            >
              <a className="dropdown-item">Event Specific</a>
            </li>
            {packageList?.length > 0 &&
              packageList.map((item: any, index: any) => {
                return (
                  <li onClick={() => handleAddClick(item)} key={index}>
                    <a className="dropdown-item">
                      {`${item.title} - ${venueName}`}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="hrline" />
      </div>
      {values?.length > 0 ? (
        values.map((item: any, index: any) => {
          return (
            <div className="EventPackageSec" key={index}>
              <div className="box">
                <div className="mb-4">
                  <label className="CommonLabel">PACKAGE</label>
                  <input
                    value={item.title}
                    onChange={(e) => {
                      const list = [...values];
                      list[index].title = e.target.value;
                      setValues(list);
                    }}
                    disabled={item._id ? true : false}
                  />
                </div>
                <div className="mb-3">
                  <label className="CommonLabel">DESCRIPTION</label>
                  <RichTag
                    editerDetails={item.description}
                    setEditerDetails={(value: any) => {
                      const list = [...values];
                      list[index].description = value;
                      setValues(list);
                    }}
                    disabled={item._id ? true : false}
                  />
                </div>
                <div className="mb-3">
                  <label className="CommonLabel">LINK</label>
                  <input
                    value={item.ticketLink}
                    onChange={(e) => {
                      const list = [...values];
                      list[index].ticketLink = e.target.value;
                      setValues(list);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label className="CommonLabel">PRICE</label>
                  <input
                    value={item.price}
                    onChange={(e) => {
                      const list = [...values];
                      list[index].price = e.target.value;
                      setValues(list);
                    }}
                  />
                </div>

                <div className="btn-box">
                  <div className="del-buttons">
                    <button
                      className="delete-btn-tickets"
                      type="button"
                      onClick={() => ConfirmDelete(index, handleRemoveClick)}
                    >
                      <span className="Delete-tickets-package">
                        <img
                          alt=""
                          src={del}
                          className="fa-solid fa-trash-can"
                        />
                        <span className="delete-team-member">
                          Delete Package
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="NoData_margin">
          <NoData content="No package is found." />
        </div>
      )}
    </div>
  );
}

export default AddPackageToEvent;
