import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../Component/Footer/Footer";
import ChildPackagesHeader from "../../Component/Header/ChildHeader/ChildPackagesHeader";
import { ToastFailure, ToastSuccess } from "../../Share/toast/ToastMsg";
import { isRichTextContextEmpty } from "../../Utills/utils";
import "./PackagesChild.scss";
import Loader from "../../Utills/Loader/Loader";
import useApiService from "../../Utills/ApiAxiosHandler/ApiAxiosHandler";
import RichTag from "../../Utills/RichTag/RichTage";

function PackagesChild() {
  const axiosInstance = useApiService();
  const { id } = useParams();
  const [packagesDescription, setPackagesDescription] = useState<any>([]);
  const [editerDetails, setEditerDetails] = useState<any>({
    id: "",
    content: "",
    title: "",
    updatedBy: "",
    updatedAt: "",
    venueIds: [],
  });
  const [venues, setVenues] = useState([]);
  const [packageType, setPackageType] = useState<any>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getPackageDescription = async () => {
    if (Number(id) != 1) {
      setLoading(true);
      await axiosInstance
        .get(`admin/package?_id=${id}`)
        .then((response) => {
          setLoading(false);
          setPackagesDescription(response.data.data);
          setPackageType(response.data.data.packageType);
          setEditerDetails({
            id: response.data.data._id,
            title: response.data.data.title,
            content: response.data.data.description || "",
            updatedBy: response.data.data.updatedBy,
            updatedAt: response.data.data.updatedAt,
            venueIds: response.data.data.venueIds,
          });
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const getAllVanues = () => {
    axiosInstance
      .get(`admin/venues?currentPage=1&pageSize=1000`)
      .then((response) => {
        setVenues(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const UpdatePackageDetails = () => {
    if (editerDetails.title.length === 0) {
      ToastFailure("Please enter package title");
      return;
    }

    if (isRichTextContextEmpty(editerDetails.content)) {
      ToastFailure("Please enter content");
      return;
    }

    if (editerDetails.venueIds.length === 0) {
      ToastFailure("Please select venue");
      return;
    }
    setLoading(true);
    axiosInstance
      .put(`admin/package`, {
        _id: editerDetails.id,
        title: editerDetails.title,
        description: editerDetails.content,
        packageType: packageType,
        venueIds: editerDetails.venueIds,
        updatedAt: 1675245353919,
        updatedBy: editerDetails.updatedBy
          ? editerDetails.updatedBy
          : "Autumn Lane",
      })
      .then((response) => {
        setLoading(false);
        ToastSuccess(response.data.message);
        navigate("/package");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleChange = (_id: any) => {
    const index = editerDetails.venueIds.indexOf(_id);
    const data = { ...editerDetails };
    if (index > -1) {
      data.venueIds.splice(index, 1);
    } else {
      data.venueIds.push(_id);
    }
    setEditerDetails(data);
  };

  const packageTypeHandler = (e: any) => {
    setPackageType(e);
  };

  const addNewPackageDetails = () => {
    if (editerDetails.title.length === 0) {
      ToastFailure("Please enter package title");
      return;
    }
    if (isRichTextContextEmpty(editerDetails.content)) {
      ToastFailure("Please enter content");
      return;
    }
    if (editerDetails.venueIds.length === 0) {
      ToastFailure("Please select venue");
      return;
    }
    setLoading(true);
    axiosInstance
      .post(`admin/package`, {
        description: editerDetails.content,
        packageType: packageType,
        title: editerDetails.title,
        venueIds: editerDetails.venueIds,
      })
      .then((response) => {
        setLoading(false);
        ToastSuccess(response.data.message);
        navigate("/package");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getPackageDescription();
    getAllVanues();
  }, [id]);

  const checkHandler = (_id: any) => {
    return editerDetails.venueIds.indexOf(_id) > -1;
  };

  return (
    <>
      <ChildPackagesHeader
        headerTitle={Number(id) == 1 ? "Add New Package" : "Edit Package"}
        updatedAt={packagesDescription.updatedAt}
        updatedBy={packagesDescription.updatedBy}
      />
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <div className="packagerChild-container TopCommonChild MainClass">
          <div className="packages-child">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="ParkingLotSec">
                    <div className="parking-lots">
                      <div className="packages-parking-title">
                        <label
                          htmlFor="TITLE"
                          className="parkings-child CommonLabel"
                        >
                          TITLE
                        </label>
                        <input
                          type="text"
                          className="parkingbook"
                          placeholder="First Name"
                          value={editerDetails.title}
                          onChange={(e) =>
                            setEditerDetails({
                              ...editerDetails,
                              title: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="active-radio-buttons">
                      <label
                        htmlFor="PACKAGE"
                        className="parkings-child CommonLabel"
                      >
                        PACKAGE
                      </label>
                      <span
                        className="file-type-text"
                        onChange={(e: any) => packageTypeHandler(e.target.id)}
                      >
                        <Form.Check
                          inline
                          label="Package"
                          name="group1"
                          type="radio"
                          id="package"
                          className="CommonLabel addparkingType"
                          value="package"
                          checked={packageType == `package`}
                        />
                        <Form.Check
                          inline
                          label="Parking"
                          name="group1"
                          className="CommonLabel addparkingType"
                          type="radio"
                          id="parking"
                          value="parking"
                          checked={packageType == `parking`}
                        />
                        <Form.Check
                          inline
                          label="Ticket Upgrade"
                          name="group1"
                          className="CommonLabel addparkingType"
                          type="radio"
                          id="ticketUpgrade"
                          value="ticketUpgrade"
                          checked={packageType == `ticketUpgrade`}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="Editor-content">
                    <div className="descrip">
                      <label
                        htmlFor="DESCRIPTION"
                        className="cription CommonLabel"
                      >
                        DESCRIPTION
                      </label>
                      <RichTag
                        editerDetails={editerDetails.content}
                        setEditerDetails={(value: any) => {
                          setEditerDetails({
                            ...editerDetails,
                            content: value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="attackes-content-area">
                    <div className="tovenue">
                      <div className="tovenue-box">
                        <div className="attacktovenue">
                          <label htmlFor="ATTACH TO VENUE" className="Collins">
                            ATTACH TO VENUE
                          </label>
                          <div className="VenueRadioBtn">
                            {venues ? (
                              venues.map(({ title, _id }, i) => {
                                return (
                                  <div className="RadioDiv" key={_id}>
                                    <input
                                      type="checkbox"
                                      onChange={() => handleChange(_id)}
                                      defaultChecked={checkHandler(_id)}
                                      id={`${i}`}
                                      className="form-check-input"
                                    />
                                    <label htmlFor="Maine">{title}</label>
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer
            isSubmit={
              Number(id) != 1 ? UpdatePackageDetails : addNewPackageDetails
            }
            isUpdate={Number(id) != 1}
            cancelSubmit={() => navigate("/package")}
          />
        </div>
      )}
    </>
  );
}

export default PackagesChild;
